.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 24px;

  h1 {
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    line-height: 1.67;
    margin-bottom: 16px;
  }

  p {
    font-weight: 300;
    line-height: 1.5;
    text-align: center;
    color: #fff;
    margin-bottom: 40px;
  }
}

.result-modal {
  position: relative;
  --result: "image";
  --displayRes: "flex";

  &::after {
    content: "";
    display: var(--displayRes);
    position: absolute;
    top: 0;
    left: calc(var(--bs-gutter-x) * 0.5);
    width: calc(100% - calc(var(--bs-gutter-x) * 0.5 * 2));
    height: 100%;
    background: var(--result) no-repeat center, #fff;
    background-size: contain;
    border-radius: var(--bs-border-radius);
    z-index: 1;
  }
}
